import {
  CircularProgress,
  MenuItem,
  Pagination,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Range } from "react-date-range";
import { useApi } from "../../ApiProvider";
import BranchCard from "../../components/BranchCard";
import DateRangeButton from "../../components/DateRangeButton";
import Fallback from "../../components/Fallback";
import PageLayout from "../../components/PageLayout";
import { meQuery } from "../../features/auth/meQuery";
import { branchPageQuery } from "../../features/branch/branchPageQuery";
import { useDebounce } from "../../utils/useDebounce";

const BranchesPage: React.FC = () => {
  const theme = useTheme();
  const { client } = useApi();
  const me = useQuery(meQuery(client));
  const [page, setPage] = useState(0);
  const [query, setQuery] = useState("");
  const [managedBranchType, setManagedBranchType] = useState<"ORDER" | "ENTRY">(
    "ORDER"
  );
  const debouncedQuery = useDebounce(query, 300);
  const [range, setRange] = useState<Range>({
    startDate: new Date(),
    endDate: undefined,
    color: theme.palette.primary.main,
  });
  const branches = useQuery(
    branchPageQuery(client, me.data?.email, {
      page,
      managedBranchType,
      startDate: range.startDate,
      endDate: range.endDate,
      query: debouncedQuery,
    })
  );
  return (
    <PageLayout
      title="페스티벌"
      info={`(${branches.data?.page.totalElements ?? 0}건)`}
    >
      <Box sx={{ display: "flex", gap: "0.5rem", flexWrap: "wrap" }}>
        <DateRangeButton range={range} onChange={setRange} />
        <Select
          size="small"
          value={managedBranchType}
          onChange={(e) =>
            setManagedBranchType(e.target.value as "ORDER" | "ENTRY")
          }
        >
          <MenuItem value="ORDER">주문관리</MenuItem>
          <MenuItem value="ENTRY">입장관리</MenuItem>
        </Select>
        <TextField
          label="검색어를 입력해 주세요."
          variant="outlined"
          size="small"
          sx={{ maxWidth: "30rem" }}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </Box>
      {branches.isError ? (
        <Fallback message="오류가 발생했습니다." />
      ) : branches.isLoading ? (
        <CircularProgress sx={{ margin: "2rem auto" }} />
      ) : branches.data == null || branches.data?.page.totalElements === 0 ? (
        <Fallback message="연결된 페스티벌이 없습니다." />
      ) : (
        <Box
          sx={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
            },
          }}
        >
          {branches.data?._embedded?.businessBranchThumbResBodies?.map(
            (branch) => (
              <BranchCard
                key={branch.idBranch + "-" + branch.idBusiness}
                idBranch={branch.idBranch}
                idBusiness={branch.idBusiness}
                image={branch.image}
                title={branch.branchName}
                subtitle={branch.businessName}
                startDate={branch.startDate}
                endDate={branch.endDate}
              />
            )
          )}
        </Box>
      )}
      {(branches.data?.page?.totalPages ?? 0) > 0 && (
        <Pagination
          sx={{ alignSelf: "center" }}
          count={branches.data?.page?.totalPages}
          page={page + 1}
          onChange={(_, value) => setPage(value - 1)}
        />
      )}
    </PageLayout>
  );
};

export default BranchesPage;
