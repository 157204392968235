import { useTheme } from "@mui/material";
import EChartsReact from "echarts-for-react";

export type EntryPerTimeChartItem = {
  startTime: string;
  endTime: string;
  attendanceCount: number;
};

export type EntryPerTimeChartProps = {
  items: EntryPerTimeChartItem[];
};

const EntryPerTimeChart: React.FC<EntryPerTimeChartProps> = ({ items }) => {
  const theme = useTheme();
  const total = items.reduce(
    (acc, current) => acc + current.attendanceCount,
    0
  );
  return (
    <EChartsReact
      style={{ height: 900 }}
      option={{
        title: {
          text: "시간대별 입장객",
        },
        yAxis: {
          type: "category",
          data: [
            "총 입장객",
            ...items.map((item) => `${item.startTime} - ${item.endTime}`),
          ],
          inverse: true,
          axisLabel: {
            interval: 0,
          },
        },
        xAxis: {
          type: "value",
        },
        series: [
          {
            data: [
              {
                value: total,
                itemStyle: { color: theme.palette.primary.main },
              },
              ...items.map((item) => ({
                value: item.attendanceCount,
                itemStyle: { color: theme.palette.secondary.main },
              })),
            ],
            type: "bar",
          },
        ],
      }}
    />
  );
};

export default EntryPerTimeChart;
