import { Box, useTheme } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { addDays } from "date-fns";
import { useState } from "react";
import { Range } from "react-date-range";
import { useApi } from "../../../../ApiProvider";
import { CategoryFilterCategory } from "../../../../components/CategoryFilter";
import DataAccordion from "../../../../components/DataAccordion";
import DateRangeButton from "../../../../components/DateRangeButton";
import Fallback from "../../../../components/Fallback";
import PendingFallback from "../../../../components/PendingFallback";
import { entryTotalDataItemPageQuery } from "../../../../features/entry/entryTotalDataItemPageQuery";
import { entryTotalDataQuery } from "../../../../features/entry/entryTotalDataQuery";
import { formatMobileNumber } from "../../../../utils/formatMobileNumber";

export type EnteranceDetailTabProps = {
  queens: CategoryFilterCategory[];
  etickets: CategoryFilterCategory[];
};

const EntriesDetailTab: React.FC<EnteranceDetailTabProps> = ({
  queens,
  etickets,
}) => {
  const { client } = useApi();
  const theme = useTheme();
  const minDate = addDays(new Date(), -3);
  const maxDate = addDays(new Date(), 3);
  const [range, setRange] = useState<Range>({
    startDate: minDate,
    endDate: maxDate,
    color: theme.palette.primary.main,
  });
  const queensData = useQueries({
    queries: queens.map((queen) =>
      entryTotalDataQuery(client, {
        idBranchMenu: queen.id,
        idsMenu: queen.products.map((product) => product.id),
      })
    ),
  });
  const eticketsData = useQueries({
    queries: etickets.map((eticket) =>
      entryTotalDataQuery(client, {
        idBranchETicket: eticket.id,
        idsETicket: eticket.products.map((product) => product.id),
      })
    ),
  });
  const queensItems = useQueries({
    queries: queens.map((queen) =>
      entryTotalDataItemPageQuery(client, {
        idBranchMenu: queen.id,
        idsMenu: queen.products.map((product) => product.id),
      })
    ),
  });
  const eticketItems = useQueries({
    queries: etickets.map((eticket) =>
      entryTotalDataItemPageQuery(client, {
        idBranchETicket: eticket.id,
        idsETicket: eticket.products.map((product) => product.id),
      })
    ),
  });
  return (
    <>
      <Box>
        <DateRangeButton
          range={range}
          onChange={setRange}
          minDate={minDate}
          maxDate={maxDate}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="0.5rem">
        {queens.length < 1 && etickets.length < 1 && (
          <Fallback
            message={"조회된 데이터가 없습니다.\n필터를 선택해 주세요."}
          />
        )}
        {queensData.map((item, index) => {
          const data = item.data;
          const items =
            queensItems[index].data?._embedded?.qrCheckThumbResBodies ?? [];
          return data == null ? (
            <PendingFallback key={queens[index].id} />
          ) : (
            <DataAccordion
              key={queens[index].id}
              title={data.categoryName}
              subtitle="퀸즈스마일 티켓"
              stats={{
                "입장율(%)": `${data.entryRate.toFixed(1)}%`,
                입장완료: data.entryCount.toLocaleString(),
                "입장 미완료": data.noEntryCount.toLocaleString(),
                "티켓 판매 총 수량": data.saleCount.toLocaleString(),
              }}
              titles={[
                "성함",
                "연락처",
                "상품명",
                "관람(수령)일자",
                "예매처",
                "수량",
                // "금액",
                "수령완료",
              ]}
              rows={items.map((row) => [
                row.name,
                formatMobileNumber(row.mobile) || "-",
                row.ticketName || "-",
                row.receiptDate || "-",
                row.reservationStore || "-",
                `${row.registerCount.toLocaleString()}개`,
                // "-원",
                `${row.receiptCount.toLocaleString()}개`,
              ])}
            />
          );
        })}
        {eticketsData.map((response, index) => {
          const data = response.data;
          const items =
            eticketItems[index].data?._embedded?.qrCheckThumbResBodies ?? [];
          return data == null ? (
            <PendingFallback key={etickets[index].id} />
          ) : (
            <DataAccordion
              key={etickets[index].id}
              title={data.categoryName}
              subtitle="E-Ticket"
              stats={{
                "입장율(%)": `${data.entryRate.toFixed(1)}%`,
                입장완료: data.entryCount.toLocaleString(),
                "입장 미완료": data.noEntryCount.toLocaleString(),
                "티켓 판매 총 수량": data.saleCount.toLocaleString(),
                등록율: `${data.registerRate.toFixed(1)}%`,
              }}
              titles={[
                "성함",
                "연락처",
                "상품명",
                "관람(수령)일자",
                "예매처",
                "수량",
                // "금액",
                "수령완료",
                "등록완료",
              ]}
              rows={items?.map((row) => [
                row.name,
                formatMobileNumber(row.mobile) || "-",
                row.ticketName || "-",
                row.receiptDate || "-",
                row.reservationStore || "-",
                `${row.registerCount.toLocaleString()}개`,
                // "-원",
                `${row.receiptCount.toLocaleString()}개`,
                `${row.registerCount.toLocaleString()}개`,
              ])}
            />
          );
        })}
      </Box>
    </>
  );
};

export default EntriesDetailTab;
