import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";

export type EntryRateTableProps = {
  date: Date;
  attendance: number;
  totalCount: number;
  attendanceByQueensSmile: number;
  totalCountByQueensSmile: number;
  attendanceByETicket: number;
  totalCountByETicket: number;
};

const EntryRateTable: React.FC<EntryRateTableProps> = ({
  date,
  attendance,
  totalCount,
  attendanceByQueensSmile,
  totalCountByQueensSmile,
  attendanceByETicket,
  totalCountByETicket,
}) => {
  return (
    <Table size="small">
      <caption>{format(date, "yyyy.MM.dd HH시 mm분")} 기준</caption>
      <TableHead>
        <TableRow>
          <TableCell sx={{ bgcolor: "grey.100" }}>합:</TableCell>
          <TableCell sx={{ bgcolor: "grey.50" }} align="right">
            {attendance.toLocaleString()}개 / {totalCount.toLocaleString()}개
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell sx={{ bgcolor: "grey.50" }}>
            퀸즈스마일 티켓 사용:
          </TableCell>
          <TableCell align="right">
            {attendanceByQueensSmile.toLocaleString()}개 /{" "}
            {totalCountByQueensSmile.toLocaleString()}개
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell sx={{ bgcolor: "grey.50" }}>E-Ticket 사용:</TableCell>
          <TableCell align="right">
            {attendanceByETicket.toLocaleString()}개 /{" "}
            {totalCountByETicket.toLocaleString()}개
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default EntryRateTable;
