import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";

export type ConfirmDialogProps = {
  open: boolean;
  onOk: () => void;
  onClose: () => void;
  message: string;
  okText?: string;
};

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  open,
  onOk,
  onClose,
  message,
  okText = "확인",
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>퀸즈스마일</DialogTitle>
      <DialogContent>
        <Typography variant="body1">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onOk();
            onClose();
          }}
        >
          {okText}
        </Button>
        <Button onClick={onClose} color="secondary">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
