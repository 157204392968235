import { AxiosInstance } from "axios";

export const orderTotalSalesKey = (idBranch: number) => [
  "orderTotalSales",
  idBranch,
];

export const orderTotalSalesQuery = (
  client: AxiosInstance,
  idBranch: number
) => ({
  // TODO: 테스트용. 제거
  enabled: false,
  queryKey: orderTotalSalesKey(idBranch),
  queryFn: () =>
    client
      .request<number>({
        method: "GET",
        url: "v1/partner/orders/total-sales",
        params: {
          idBranch,
        },
      })
      .then((response) => response.data),
});
