import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

export type SectionProps = {
  title?: ReactNode;
  right?: ReactNode;
  children?: ReactNode;
};

const Section: React.FC<SectionProps> = ({ title, right, children }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        flex: "1 1 auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="h6" fontWeight="bold" sx={{ marginRight: "auto" }}>
          {title}
        </Typography>
        <Typography color="secondary">{right}</Typography>
      </Box>
      {children}
    </Box>
  );
};

export default Section;
