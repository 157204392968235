import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useApi } from "../../../../ApiProvider";
import { CategoryFilterCategory } from "../../../../components/CategoryFilter";
import EntryRateChart from "../../../../components/EntryRateChart";
import EntryRateTable from "../../../../components/EntryRateTable";
import { entrySummaryDataQuery } from "../../../../features/entry/entrySummaryDataQuery";

export type EntriesRateTabProps = {
  queens: CategoryFilterCategory[];
  etickets: CategoryFilterCategory[];
};

const EntriesRateTab: React.FC<EntriesRateTabProps> = ({
  queens,
  etickets,
}) => {
  const { client } = useApi();
  const idsMenu = queens.map((q) => q.products.map((p) => p.id)).flat();
  const idsETicket = etickets.map((e) => e.products.map((p) => p.id)).flat();
  const entrySummary = useQuery(
    entrySummaryDataQuery(client, idsMenu, idsETicket)
  );
  return entrySummary.data == null ? null : (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "grid",
            alignItems: "flex-start",
            gridTemplateColumns: {
              xs: "1fr",
              md: "1fr 1fr",
            },
          }}
        >
          <Box sx={{ overflow: "hidden" }}>
            {/** TODO: attendanceRate 데이터 잘못됨 */}
            <EntryRateChart
              rate={
                (entrySummary.data.attendance /
                  Math.max(entrySummary.data.totalCount, 1)) *
                100
              }
            />
          </Box>
          <EntryRateTable
            date={parseISO(entrySummary.data.searchDateTime)}
            attendance={entrySummary.data.attendance}
            totalCount={entrySummary.data.totalCount}
            attendanceByQueensSmile={entrySummary.data.attendanceByQueensSmile}
            totalCountByQueensSmile={entrySummary.data.totalCountByQueensSmile}
            attendanceByETicket={entrySummary.data.attendanceByETicket}
            totalCountByETicket={entrySummary.data.totalCountByETicket}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EntriesRateTab;
