import { Box, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { Link, useParams } from "react-router-dom";
import { useApi } from "../../../../ApiProvider";
import CalculationCard from "../../../../components/CalculationCard";
import Fallback from "../../../../components/Fallback";
import PageLayout from "../../../../components/PageLayout";
import { meQuery } from "../../../../features/auth/meQuery";
import { calculationPageQuery } from "../../../../features/calculation/calculationPageQuery";

const CalculationPage: React.FC = () => {
  const { client } = useApi();
  const amISuper = useQuery({
    ...meQuery(client),
    select: (me) => me.authorities.includes("ROLE_SUPER"),
  });
  const userType = amISuper.data ? "super" : "partner";
  const params = useParams();
  const [idBranch, idBusiness] = params.id!.split("-").map(Number);
  const calculations = useQuery({
    ...calculationPageQuery(client, {
      idBranch,
      idsBusiness: [idBusiness],
    }),
    // enabled: amISuper.data === false,
  });
  return (
    <PageLayout
      title="정산관리"
      actions={[
        userType === "super" && (
          <Button variant="contained" component={Link} to="../calculations/add">
            신규
          </Button>
        ),
      ].filter(Boolean)}
    >
      {calculations.data == null ||
        (calculations.data.content.length < 1 && (
          <Fallback message="정산서가 없습니다." />
        ))}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: {
            xs: "1fr",
            md: "1fr 1fr",
          },
          gap: "1rem",
        }}
      >
        {/** TODO: 중복건 해결 후 key index에서 id로 바꾸기 */}
        {calculations.data?.content.map((calculation, index) => (
          <CalculationCard
            key={index}
            id={calculation.id}
            branchName={calculation.branchName}
            businessName={calculation.businessName}
            status={calculation.calculationStatus}
            sentAt={
              calculation.sendDateTime == null
                ? undefined
                : parseISO(calculation.sendDateTime)
            }
          />
        ))}
      </Box>
    </PageLayout>
  );
};

export default CalculationPage;
