import { AxiosInstance } from "axios";

export type EntryMenuCategory = {
  id: number;
  name: string;
};

export type EntryETicketCategory = {
  id: number;
  name: string;
};

export const entryCategoriesKey = (idBranch: number) => [
  "entryCategories",
  idBranch,
];

export const entryCategoriesQuery = (
  client: AxiosInstance,
  idBranch: number
) => ({
  queryKey: entryCategoriesKey(idBranch),
  queryFn: () =>
    client
      .request<{
        branchMenus: EntryMenuCategory[];
        branchETickets: EntryETicketCategory[];
      }>({
        method: "GET",
        url: "v1/partner/entries/categories",
        params: {
          idBranch,
        },
      })
      .then((response) => response.data),
});
