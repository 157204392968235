import { Box, Button, TextField } from "@mui/material";
import { DateField } from "@mui/x-date-pickers";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { addDays, parseISO } from "date-fns";
import { FormEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ApiError, { useApi } from "../../../../ApiProvider";
import FilePicker from "../../../../components/FilePicker";
import PageLayout from "../../../../components/PageLayout";
import { calculationCreateMutation } from "../../../../features/calculation/calculationCreateMutation";
import { calculationQuery } from "../../../../features/calculation/calculationQuery";
import { calculationUpdateMutation } from "../../../../features/calculation/calculationUpdateMutation";
import { fileUploadMutation } from "../../../../features/file/fileUploadMutation";

type CalculationFormValues = {
  title?: string;
  name?: string;
  email?: string;
  phone?: string;
  calculatedEndDate?: Date;
  memo?: string;
  fileUrls?: string[];
};

const mockValues: CalculationFormValues = {
  title: "워터밤 수원 정산서",
  name: "전형지",
  email: "test@test.com",
  phone: "010-0000-0000",
  calculatedEndDate: addDays(new Date(), 10),
  memo: "정산서 확인 후 문제 없으시면 정산 마감일까지 승인 부탁드립니다.",
  fileUrls: [],
};

const CalculationFormPage: React.FC = () => {
  const navigate = useNavigate();
  const { client } = useApi();
  const queryClient = useQueryClient();
  const [values, setValues] = useState<CalculationFormValues>(
    process.env.NODE_ENV === "development" ? mockValues : {}
  );
  const params = useParams();
  const [idBranch, idBusiness] = params
    .id!.split("-")
    .map((id) => parseInt(id));
  const idCalculation =
    params.idCalculation == null
      ? null
      : parseInt(params.idCalculation as string, 10);
  const calculation = useQuery({
    ...calculationQuery(client, idCalculation!),
  });
  useEffect(() => {
    if (calculation.data != null) {
      setValues({
        ...calculation.data,
        calculatedEndDate: parseISO(calculation.data.calculatedEndDate),
      });
    }
  }, [calculation.data]);
  const create = useMutation(calculationCreateMutation(client, queryClient));
  const update = useMutation(calculationUpdateMutation(client, queryClient));
  const upload = useMutation(fileUploadMutation(client));
  const handleSumbmit = (e: FormEvent) => {
    e.preventDefault();
    if (idCalculation == null) {
      create.mutate(
        {
          idBranch,
          idBusiness,
          calculatedStartDate: new Date(),
          calculatedEndDate: values.calculatedEndDate!,
          name: values.name!,
          email: values.email!,
          phone: values.phone!,
          memo: values.memo!,
          fileUrls: values.fileUrls ?? [],
          reasonOfDisapproval: "",
          calculationStatus: "INIT",
        },
        {
          // TODO: 생성된 정산서 id 필요
          onSuccess: () => navigate(`../calculations`),
          onError: ApiError.alertError,
        }
      );
    } else {
      update.mutate(
        {
          id: idCalculation,
          idBranch,
          idBusiness,
          calculatedStartDate: new Date(),
          calculatedEndDate: values.calculatedEndDate!,
          name: values.name!,
          email: values.email!,
          phone: values.phone!,
          memo: values.memo!,
          fileUrls: values.fileUrls ?? [],
          reasonOfDisapproval: "",
          calculationStatus: "MODIFY",
        },
        {
          // TODO: 생성된 정산서 id 필요
          onSuccess: () => navigate(`../calculations`),
          onError: ApiError.alertError,
        }
      );
    }
  };
  const handleUpload = (file: File) => {
    upload.mutate(file, {
      onSuccess: (result) => {
        setValues({
          ...values,
          fileUrls: [...(values.fileUrls ?? []), result.url],
        });
      },
      onError: ApiError.alertError,
    });
  };
  return (
    <PageLayout title={"정산서 작성"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1.5rem",
        }}
        component="form"
        onSubmit={handleSumbmit}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          {/* <TextField
            label="제목"
            variant="standard"
            size="small"
            sx={{ maxWidth: "30rem" }}
            value={values?.title}
            onChange={(e) => setValues({ ...values, title: e.target.value })}
          /> */}
          <TextField
            label="정산 담당자"
            variant="standard"
            size="small"
            sx={{ maxWidth: "30rem" }}
            value={values?.name || ""}
            onChange={(e) => setValues({ ...values, name: e.target.value })}
          />
          <TextField
            type="email"
            label="정산 담당자 이메일"
            variant="standard"
            size="small"
            sx={{ maxWidth: "30rem" }}
            value={values?.email || ""}
            onChange={(e) => setValues({ ...values, email: e.target.value })}
          />
          <TextField
            label="정산 담당자 연락처"
            variant="standard"
            size="small"
            sx={{ maxWidth: "30rem" }}
            value={values?.phone || ""}
            onChange={(e) => setValues({ ...values, phone: e.target.value })}
          />
          <DateField
            label="정산 마감일"
            size="small"
            variant="standard"
            sx={{ maxWidth: "30rem" }}
            format="yyyy-MM-dd"
            value={values?.calculatedEndDate}
            onChange={(value) =>
              setValues({ ...values, calculatedEndDate: value ?? undefined })
            }
          />
          <TextField
            multiline
            label="담당자 전달사항"
            variant="standard"
            size="small"
            sx={{ maxWidth: "30rem" }}
            minRows={3}
            value={values?.memo || ""}
            onChange={(e) => setValues({ ...values, memo: e.target.value })}
          />
          <Box sx={{ maxWidth: "30rem" }}>
            <FilePicker
              files={values?.fileUrls}
              onUpload={handleUpload}
              onDelete={(url) =>
                setValues({
                  ...values,
                  fileUrls: values.fileUrls?.filter((f) => f !== url),
                })
              }
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button variant="contained" type="submit">
            저장
          </Button>
        </Box>
      </Box>
    </PageLayout>
  );
};

export default CalculationFormPage;
