import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Outlet, useParams } from "react-router-dom";
import { useApi } from "../../../ApiProvider";
import BranchMenu from "../../../components/BranchMenu";
import PendingFallback from "../../../components/PendingFallback";
import { branchQuery } from "../../../features/branch/branchQuery";

const BranchDetailPage: React.FC = () => {
  const params = useParams();
  const { client } = useApi();
  const [idBranch, idBusiness] = params
    .id!.split("-")
    .map((id) => parseInt(id));
  const branch = useQuery(branchQuery(client, idBranch, idBusiness));
  return branch.data == null ? (
    <PendingFallback />
  ) : (
    <Box
      sx={{
        flex: "1 1 auto",
        display: "flex",
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <BranchMenu name={branch.data?.branchName} />
      <Outlet />
    </Box>
  );
};

export default BranchDetailPage;
