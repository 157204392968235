import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
} from "@mui/material";
import { format, isSameDay } from "date-fns";
import { Link } from "react-router-dom";

export type FestivalCardProps = {
  idBranch: number;
  idBusiness: number;
  image: string;
  title?: string;
  subtitle?: string;
  startDate: Date;
  endDate: Date;
};

const BranchCard: React.FC<FestivalCardProps> = ({
  idBranch,
  idBusiness,
  image,
  title,
  subtitle,
  startDate,
  endDate,
}) => {
  const dates = isSameDay(startDate, endDate)
    ? [startDate]
    : [startDate, endDate];
  const dateText = dates.map((date) => format(date, "yyyy-MM-dd")).join(" ~ ");

  return (
    <Card>
      <CardActionArea
        component={Link}
        sx={{ display: "flex" }}
        to={`/branches/${idBranch}-${idBusiness}`}
      >
        <Box
          sx={{
            width: "10rem",
            minHeight: "10rem",
            alignSelf: "stretch",
            flex: "0 0 auto",
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center center",
          }}
        />
        <CardContent
          sx={{
            padding: "2rem",
            flex: "1 1 auto",
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
          }}
        >
          <Typography
            component="div"
            variant="h6"
            fontWeight="600"
            lineHeight={1.2}
            dangerouslySetInnerHTML={{ __html: title ?? "" }}
          />
          <Typography
            component="div"
            color="text.secondary"
            whiteSpace="pre-line"
            lineHeight={1.2}
          >
            {subtitle}
          </Typography>
          <Typography
            component="div"
            variant="body2"
            color="text.secondary"
            whiteSpace="pre-line"
            lineHeight={1.2}
          >
            {dateText}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default BranchCard;
