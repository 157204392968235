import { Download, ExpandMoreOutlined } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import { BiSortAlt2, BiSortDown, BiSortUp } from "react-icons/bi";

export type DataAccordionProps = {
  title?: string;
  subtitle?: string;
  stats: Record<string, string>;
  titles: string[];
  rows: string[][];
  onExcelDownload?: () => void;
};

type SortOption = {
  index: number;
  direction: "asc" | "desc";
};

const DataAccordion: React.FC<DataAccordionProps> = ({
  title,
  subtitle,
  stats,
  titles,
  rows,
  onExcelDownload,
}) => {
  const [sort, setSort] = useState<SortOption>();
  const handleSort = (index: number) => {
    if (sort?.index !== index) {
      setSort({
        index,
        direction: "asc",
      });
    } else if (sort?.direction === "asc") {
      setSort({
        index,
        direction: "desc",
      });
    } else {
      setSort(undefined);
    }
  };
  const sorted =
    sort == null
      ? rows
      : [...rows].sort((a, b) => {
          const aString = a[sort!.index];
          const bString = b[sort!.index];
          const sign = sort.direction === "asc" ? 1 : -1;
          if (aString === bString) return 0;
          return (aString > bString ? 1 : -1) * sign;
        });
  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreOutlined />}
        sx={{
          ".MuiAccordionSummary-content": {
            overflow: "hidden",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1 1 auto",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            alignItems: {
              sm: "",
              md: "center",
            },
            gap: "1rem",
            paddingRight: "1rem",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              minWidth: "10rem",
            }}
          >
            <Typography
              fontWeight="bold"
              dangerouslySetInnerHTML={{ __html: title ?? "" }}
            />
            {subtitle && (
              <Typography
                variant="body2"
                color="text.secondary"
                dangerouslySetInnerHTML={{ __html: subtitle ?? "" }}
              />
            )}
          </Box>
          <TableContainer sx={{ overflowX: "auto" }}>
            <Table
              size="small"
              sx={{
                borderLeft: { xs: 0, md: 1 },
                borderColor: { xs: "divider", md: "divider" },
                minWidth: "30rem",
              }}
            >
              <TableHead>
                <TableRow>
                  {Object.keys(stats).map((title) => (
                    <TableCell align="center" key={title}>
                      {title}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {Object.values(stats).map((value) => (
                    <TableCell
                      align="center"
                      sx={{
                        borderBottom: 0,
                        color: "text.secondary",
                        maxWidth: "5rem",
                      }}
                    >
                      {value}
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </AccordionSummary>
      {false && (
        <AccordionDetails
          sx={{
            borderTop: 1,
            borderColor: "divider",
            padding: 0,
            overflow: "hidden",
          }}
        >
          <TableContainer
            sx={{ overflowX: "auto", maxHeight: "30rem", overflow: "auto" }}
          >
            <Table
              sx={{ minWidth: "70rem", whiteSpace: "nowrap" }}
              stickyHeader
            >
              <TableHead>
                <TableRow sx={{ bgcolor: "grey.100" }}>
                  {titles.map((title, index) => (
                    <TableCell align="center">
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "0.1rem",
                        }}
                      >
                        <Typography variant="body2">{title}</Typography>
                        <IconButton
                          size="small"
                          onClick={() => handleSort(index)}
                          color={sort?.index === index ? "primary" : "default"}
                        >
                          {sort?.index === index ? (
                            sort.direction === "asc" ? (
                              <BiSortUp />
                            ) : (
                              <BiSortDown />
                            )
                          ) : (
                            <BiSortAlt2 />
                          )}
                        </IconButton>
                      </Box>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {sorted.length < 1 ? (
                  <TableCell
                    align="center"
                    colSpan={titles.length}
                    sx={{
                      color: "text.disabled",
                    }}
                  >
                    데이터가 없습니다.
                  </TableCell>
                ) : (
                  sorted.map((row, i) => (
                    <TableRow key={i}>
                      {row.map((col, j) => (
                        <TableCell
                          align="center"
                          sx={{
                            color: "text.secondary",
                          }}
                        >
                          {col}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            sx={{
              padding: "1.5rem",
              display: "flex",
              gap: "1.5rem",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography variant="body2" color="text.secondary">
              총 {rows.length}건
            </Typography>
            {onExcelDownload && (
              <Button
                color="success"
                variant="contained"
                disabled={rows.length < 1}
                startIcon={<Download />}
                onClick={onExcelDownload}
              >
                엑셀 다운로드
              </Button>
            )}
          </Box>
        </AccordionDetails>
      )}
    </Accordion>
  );
};

export default DataAccordion;
