import { AxiosInstance } from "axios";

export type OrderCategory = {
  id: number;
  name: string;
};

export const orderCategoriesKey = (idBranch: number) => [
  "orderCategories",
  idBranch,
];

export const orderCategoriesQuery = (
  client: AxiosInstance,
  idBranch: number
) => ({
  queryKey: orderCategoriesKey(idBranch),
  queryFn: () =>
    client
      .request<OrderCategory[]>({
        method: "GET",
        url: `v1/partner/orders/categories`,
        params: {
          idBranch,
        },
      })
      .then((response) => response.data),
});
