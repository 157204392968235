export function formatMobileNumber(number: string) {
  if (number.length === 12) {
    return (
      number.slice(0, 3) + "-" + number.slice(3, 8) + "-" + number.slice(8, 12)
    );
  } else if (number.length === 11) {
    return (
      number.slice(0, 3) + "-" + number.slice(3, 7) + "-" + number.slice(7, 11)
    );
  }
}
