import { AxiosInstance } from "axios";

export const orderTotalDataExcelDownload = (
  client: AxiosInstance,
  idBranchMenu: number,
  idsMenu: number[],
  localDate?: Date
) =>
  client.request<Blob>({
    method: "POST",
    url: "v1/partner/orders/total-data/excel",
    params: {
      idBranchMenu,
      idsMenu,
      localDate,
    },
    responseType: "blob",
  });
