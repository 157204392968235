import { Button, Dialog, DialogActions } from "@mui/material";
import ko from "date-fns/locale/ko";
import { useState } from "react";
import { DateRange, Range } from "react-date-range";

export type DateRangeDialogProps = {
  ranges: Range[];
  onChange: (newRanges: Range[]) => void;
  open: boolean;
  onClose: () => void;
  minDate?: Date;
  maxDate?: Date;
};

const DateRangeDialog: React.FC<DateRangeDialogProps> = ({
  ranges,
  onChange,
  open,
  onClose,
  minDate,
  maxDate,
}) => {
  const [innerRanges, setInnerRanges] = useState(ranges);
  return (
    <Dialog open={open} onClose={onClose}>
      <DateRange
        ranges={innerRanges}
        onChange={(dict) => setInnerRanges(Object.values(dict))}
        minDate={minDate}
        maxDate={maxDate}
        locale={ko}
        retainEndDateOnFirstSelection
      />
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            onChange(innerRanges);
            onClose();
          }}
        >
          확인
        </Button>
        <Button variant="outlined" color="secondary" onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DateRangeDialog;
