import { AxiosInstance } from "axios";

export type Authority =
  | "ROLE_USER"
  | "ROLE_PROVIDER"
  | "ROLE_CASHER"
  | "ROLE_PARTNER"
  | "ROLE_SUPER";

export type Me = {
  id: number;
  email: string;
  fullname: string;
  nickname: string;
  mobile: string;
  authorities: Authority[];
};

export const meKey = ["me"];

export const meQuery = (client: AxiosInstance) => ({
  queryKey: meKey,
  queryFn: () =>
    client
      .request<Me>({
        method: "GET",
        url: "v1/partner/me",
      })
      .then((response) => response.data),
});
