import EChartsReact from "echarts-for-react";

export type EntryRateChartProps = {
  rate: number;
};

const EntryRateChart: React.FC<EntryRateChartProps> = ({ rate }) => {
  return (
    <EChartsReact
      option={{
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            color: ["#FF006E", "#eee"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 0,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "inner",
              formatter: "{d}%",
              fontSize: 14,
              fontWeight: "bold",
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: 40,
            //     fontWeight: "bold",
            //   },
            // },
            labelLine: {
              show: false,
            },
            data: [
              { value: rate.toFixed(1), name: "입장" },
              { value: (100 - rate).toFixed(1), name: "비입장" },
            ],
          },
        ],
      }}
    />
  );
};

export default EntryRateChart;
