import { Box, Button, Card, Chip, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export type CalculationCardProps = {
  id: number;
  branchName: string;
  businessName: string;
  status: "INIT" | "MODIFY" | "SUBMIT" | "APPROVAL" | "DISAPPROVAL";
  sentAt?: Date;
};

const CalculationCard: React.FC<CalculationCardProps> = ({
  id,
  branchName,
  businessName,
  status,
  sentAt,
}) => {
  // const dateText = sentAt != null ? format(sentAt, "yyyy-MM-dd") : "";
  return (
    <Card sx={{ width: "100%", display: "flex" }}>
      {/* <CardMedia
        component="img"
        sx={{
          width: "30%",
          aspectRatio: "1",
          objectFit: "cover",
          flex: "0 0 auto",
        }}
        title="이미지"
        image="http://waterbombfestival.com/wp-content/uploads/2022/09/wb2023_suwon_eb.jpg"
      /> */}
      <Box
        sx={{
          padding: "1rem",
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "0.5rem",
        }}
      >
        {status === "INIT" ? (
          <Chip color="default" label={`작성중`} />
        ) : status === "SUBMIT" ? (
          <Chip color="info" label={`전송됨`} />
        ) : status === "APPROVAL" ? (
          <Chip color="success" label={`승인됨`} />
        ) : status === "DISAPPROVAL" ? (
          <Chip color="error" label={`반려됨`} />
        ) : status === "MODIFY" ? (
          <Chip color="default" label={`수정중`} />
        ) : null}
        <Typography fontWeight="bold">{branchName}</Typography>
        <Typography variant="body2">{businessName}</Typography>
        <Button
          fullWidth
          variant="contained"
          // disabled={status === "INIT" && userType === "partner"}
          component={Link}
          to={id.toString()}
        >
          정산서 확인
        </Button>
      </Box>
    </Card>
  );
};

export default CalculationCard;
