import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import { useQueries, useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useApi } from "../../../../ApiProvider";
import CategoryFilter, {
  CategoryFilterCategory,
} from "../../../../components/CategoryFilter";
import PageLayout from "../../../../components/PageLayout";
import { entryCategoriesQuery } from "../../../../features/entry/entryCategoriesQuery";
import {
  entryMenusQuery,
  EntryMenusQueryResult,
} from "../../../../features/entry/entryMenusQuery";
import EntriesDetailTab from "./detail";
import EntriesPerTimeTab from "./per-time";
import EntriesRateTab from "./rate";

const BranchEntriesPage: React.FC = () => {
  const [tab, setTab] = useState("detail");
  const params = useParams();
  const id = parseInt(params.id!);
  const { client } = useApi();
  const [queensSelected, setQueensSelected] = useState<
    CategoryFilterCategory[]
  >([]);
  const [eTicketsSelected, setETicketsSelected] = useState<
    CategoryFilterCategory[]
  >([]);

  // 입장 카테고리 조회
  const entryCategories = useQuery(entryCategoriesQuery(client, id));

  // 카테고리별 메뉴 조회
  const entryMenus = useQueries({
    queries:
      entryCategories.data?.branchMenus.map((c) => ({
        ...entryMenusQuery(client, c.id, undefined),
        select: (data: EntryMenusQueryResult) => data.menus,
      })) ?? [],
  });

  // 카테고리별 이티켓 조회
  const entryETickets = useQueries({
    queries:
      entryCategories.data?.branchETickets.map((c) => ({
        ...entryMenusQuery(client, undefined, c.id),
        select: (data: EntryMenusQueryResult) => data.etickets,
      })) ?? [],
  });

  // 카테고리 셀렉터 컴포넌트에 쓰일 데이터 가공 (일반 메뉴)
  const normalCategories = (entryCategories.data?.branchMenus ?? []).map(
    (category, index) => ({
      ...category,
      products: entryMenus[index].data ?? [],
    })
  );

  // 카테고리 셀렉터 컴포넌트에 쓰일 데이터 가공 (E-Ticket 메뉴)
  const eticketCategories = (entryCategories.data?.branchETickets ?? []).map(
    (category, index) => ({
      ...category,
      products: entryETickets[index].data ?? [],
    })
  );

  return (
    <PageLayout title="입장관리">
      <Box display="flex" flexDirection="column" gap="2rem">
        {normalCategories.length > 0 && (
          <CategoryFilter
            title="퀸즈스마일"
            categories={normalCategories}
            selected={queensSelected}
            setSelected={setQueensSelected}
          />
        )}
        {eticketCategories.length > 0 && (
          <CategoryFilter
            title="E-Ticket"
            categories={eticketCategories}
            selected={eTicketsSelected}
            setSelected={setETicketsSelected}
          />
        )}
        <Tabs value={tab} onChange={(e, newTab) => setTab(newTab)}>
          {/* <Tab label="입장률" value="rate" />
          <Tab label="시간대별 입장정보" value="per-time" /> */}
          <Tab label="상세보기" value="detail" />
        </Tabs>
      </Box>
      {tab === "rate" ? (
        <EntriesRateTab queens={queensSelected} etickets={eTicketsSelected} />
      ) : tab === "per-time" ? (
        <EntriesPerTimeTab
          queens={queensSelected}
          etickets={eTicketsSelected}
        />
      ) : (
        <EntriesDetailTab queens={queensSelected} etickets={eTicketsSelected} />
      )}
    </PageLayout>
  );
};

export default BranchEntriesPage;
