import { DateRange as DateRangeIcon } from "@mui/icons-material";
import { Button } from "@mui/material";
import { format } from "date-fns";
import { useState } from "react";
import { Range } from "react-date-range";
import DateRangeDialog from "./DateRangeDialog";

export type DateRangeButtonProps = {
  min?: Date;
  max?: Date;
  range: Range;
  onChange: (newRange: Range) => void;
  minDate?: Date;
  maxDate?: Date;
};

const DateRangeButton: React.FC<DateRangeButtonProps> = ({
  range,
  onChange,
  minDate,
  maxDate,
}) => {
  const [open, setOpen] = useState(false);
  const label = [range?.startDate, range?.endDate]
    .map((d) => (d == null ? "" : format(d, "yyyy-MM-dd")))
    .join(" ~ ")
    .trim();
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant="contained"
        startIcon={<DateRangeIcon />}
      >
        {label}
      </Button>
      <DateRangeDialog
        open={open}
        onClose={() => setOpen(false)}
        ranges={[range]}
        onChange={(ranges) => onChange(ranges[0])}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
};

export default DateRangeButton;
