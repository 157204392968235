import { QueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { calculationKey, CalculationStatus } from "./calculationQuery";

export type CalculationUpdateInput = {
  id: number;
  idBusiness: number;
  idBranch: number;
  calculatedStartDate: Date;
  calculatedEndDate: Date;
  name: string;
  email: string;
  phone: string;
  memo: string;
  fileUrls: string[];
  calculationStatus: CalculationStatus;
  reasonOfDisapproval: string;
};

export const calculationUpdateMutation = (
  client: AxiosInstance,
  queryClient: QueryClient
) => ({
  mutationFn: (input: CalculationUpdateInput) =>
    client
      .request({
        method: "PUT",
        url: `v1/calculations/update/${input.id}`,
        data: input,
      })
      .then((response) => response.data),
  onSuccess: (_: unknown, input: CalculationUpdateInput) => {
    queryClient.invalidateQueries(calculationKey(input.id));
  },
});
