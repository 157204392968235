import { AxiosInstance } from "axios";

export type EsMenuThumbResBody = {
  name: string;
  mobile: string;
  menuName: string;
  receiptDate: string;
  reservationStore: string;
  count: number;
  price: number;
  receiptCount: number;
};

export type OrderTotalDataItemPage = {
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: 0;
  };
  _embedded?: {
    eSMenuThumbResBodies?: EsMenuThumbResBody[];
  };
};

export type OrderTotalDataItemPageParams = {
  idBranchMenu: number;
  idsMenu: number[];
  localDate?: Date;
};

export const orderTotalDataItemPageKey = (
  params: OrderTotalDataItemPageParams
) => ["orderTotalDataItemPage", params];

export const orderTotalDataItemPageQuery = (
  client: AxiosInstance,
  params: OrderTotalDataItemPageParams
) => ({
  queryKey: orderTotalDataItemPageKey(params),
  queryFn: () => {
    return client
      .request<OrderTotalDataItemPage>({
        method: "GET",
        url: "v1/partner/orders/total-data/list",
        params,
      })
      .then((response) => response.data);
  },
});
