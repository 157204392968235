import { Box, CircularProgress } from "@mui/material";

export type PendingFallbackProps = {};

const PendingFallback: React.FC<PendingFallbackProps> = () => {
  return (
    <Box
      sx={{
        flex: "1 1 auto",
        padding: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default PendingFallback;
