import { QueryClient } from "@tanstack/react-query";
import { AxiosInstance } from "axios";
import { calculationPageKey } from "./calculationPageQuery";
import { CalculationStatus } from "./calculationQuery";

export type CalculationCreateInput = {
  idBusiness: number;
  idBranch: number;
  calculatedStartDate: Date;
  calculatedEndDate: Date;
  name: string;
  email: string;
  phone: string;
  memo: string;
  fileUrls: string[];
  calculationStatus: CalculationStatus;
  reasonOfDisapproval: string;
};

export const calculationCreateMutation = (
  client: AxiosInstance,
  queryClient: QueryClient
) => ({
  mutationFn: (input: CalculationCreateInput) =>
    client
      .request({
        method: "POST",
        url: "v1/calculations/create",
        data: input,
      })
      .then((response) => response.data),
  onSuccess: (_: unknown, input: CalculationCreateInput) => {
    queryClient.invalidateQueries(calculationPageKey());
  },
});
