import { AxiosInstance } from "axios";

export type EntryMenuOption = {
  id: number;
  name: string;
};

export type EntryMenu = {
  id: number;
  name: string;
  options: EntryMenuOption[];
};

export type EntryETicketOption = {
  id: number;
  name: string;
};

export type EntryETicket = {
  id: number;
  name: string;
  options: EntryETicketOption[];
};

export type EntryMenusQueryResult = {
  menus: EntryMenu[];
  etickets: EntryETicket[];
};

export const entryMenusKey = (
  idBranchMenu?: number,
  idBranchETicket?: number
) => ["entryMenus", idBranchMenu, idBranchETicket];

export const entryMenusQuery = (
  client: AxiosInstance,
  idBranchMenu?: number,
  idBranchETicket?: number
) => ({
  queryKey: entryMenusKey(idBranchMenu, idBranchETicket),
  queryFn: () =>
    client
      .request<EntryMenusQueryResult>({
        method: "GET",
        url: "v1/partner/entries/menus",
        params: {
          idBranchMenu,
          idBranchETicket,
        },
      })
      .then((response) => response.data),
});
