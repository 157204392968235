import { AxiosInstance } from "axios";
import { format, parseISO } from "date-fns";

export type Branch = {
  branchName: string;
  businessName: string;
  idBranch: number;
  idBusiness: number;
  image: string;
  startDate: Date;
  endDate: Date;
};

export type BranchPageParams = {
  startDate?: Date;
  endDate?: Date;
  page?: number;
  size?: number;
  query?: string;
  managedBranchType?: "ORDER" | "ENTRY";
};

export type BranchPage = {
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: 0;
  };
  _embedded?: {
    businessBranchThumbResBodies?: Branch[];
  };
};

export const branchPageKey = (email?: string, params?: BranchPageParams) =>
  ["branches", email, params].filter(Boolean);

export const branchPageQuery = (
  client: AxiosInstance,
  email?: string,
  params?: BranchPageParams
) => ({
  queryKey: branchPageKey(email, params),
  queryFn: () =>
    client
      .request<BranchPage>({
        method: "GET",
        url: "v1/partner/branches",
        params: {
          ...params,
          sort: "startDate,desc",
          startDate:
            params?.startDate == null
              ? undefined
              : format(params.startDate, "yyyy-MM-dd"),
          endDate:
            params?.endDate == null
              ? undefined
              : format(params.endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => ({
        ...response.data,
        _embedded: {
          ...response.data._embedded,
          businessBranchThumbResBodies:
            response.data._embedded?.businessBranchThumbResBodies?.map(
              (data) => ({
                ...data,
                startDate: parseISO(data.startDate as unknown as string),
                endDate: parseISO(data.endDate as unknown as string),
              })
            ),
        },
      })),
  enabled: email != null,
});
