import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { format } from "date-fns";

export type EntryPerTimeTableItem = {
  startTime: string;
  endTime: string;
  attendanceCount: number;
};

export type EntryPerTimeTableProps = {
  date: Date;
  items: EntryPerTimeTableItem[];
};

const EntryPerTimeTable: React.FC<EntryPerTimeTableProps> = ({
  date,
  items,
}) => {
  const total = items.reduce(
    (acc, current) => acc + current.attendanceCount,
    0
  );
  return (
    <Table size="small">
      <caption>{format(date, "yyyy.MM.dd HH:mm분 기준")}</caption>
      <TableBody>
        {items.map((item) => (
          <TableRow key={item.startTime}>
            <TableCell sx={{ bgcolor: "grey.100" }}>
              {item.startTime} - {item.endTime}
            </TableCell>
            <TableCell align="right">
              {item.attendanceCount.toLocaleString()}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableHead>
        <TableRow>
          <TableCell sx={{ bgcolor: "grey.200" }}>총 입장객</TableCell>
          <TableCell sx={{ bgcolor: "grey.100" }} align="right">
            {total.toLocaleString()}
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default EntryPerTimeTable;
