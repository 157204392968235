import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";

export type PageLayoutProps = {
  title?: ReactNode;
  children?: ReactNode;
  actions?: ReactNode[];
  info?: ReactNode;
};

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  children,
  actions,
  info,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: {
          xs: "3rem 1rem",
          sm: "4rem 2rem",
        },
        width: {
          xs: "100%",
          md: "90%",
        },
        maxWidth: "70rem",
        margin: "0 auto",
        gap: "1.5rem",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <Typography variant="h1" sx={{ marginRight: "auto" }}>
          {title}
        </Typography>
        {info && (
          <Typography color="text.secondary" variant="h5">
            {info}
          </Typography>
        )}
        {actions && actions.length > 0 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            {actions}
          </Box>
        )}
      </Box>
      {children}
    </Box>
  );
};

export default PageLayout;
