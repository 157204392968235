import { AxiosInstance } from "axios";

export interface LoginRequest {
  userEmail: string;
  password: string;
  rememberEmail: boolean;
}

export interface LoginResponse {
  access_token: string;
}

export const loginMutation = (client: AxiosInstance) => ({
  mutationFn: (data: LoginRequest) =>
    client.request<LoginResponse>({
      method: "POST",
      url: "v1/partner/login",
      data,
    }),
});
