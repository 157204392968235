import { ChevronRight, ExpandMore } from "@mui/icons-material";
import { TreeItem, TreeView } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useDraft } from "../utils/useDraft";

export type CategoryDialogProduct = {
  id: number;
  name: string;
  options?: CategoryDialogOption[];
};

export type CategoryDialogOption = {
  id: number;
  name: string;
};

export type CategoryDialogProps = {
  open: boolean;
  onClose: () => void;
  name: string;
  products: CategoryDialogProduct[];
  selected: CategoryDialogProduct[];
  setSelected: (selected: CategoryDialogProduct[]) => void;
};

const CategoryDialog: React.FC<CategoryDialogProps> = ({
  open,
  onClose,
  name,
  products,
  selected,
  setSelected,
}) => {
  const [expanded, setExpanded] = useState<string[]>(["all"]);
  const [innerSelected, setInnerSelected] = useDraft(selected);
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>필터: {name}</DialogTitle>
      <DialogContent>
        <TreeView
          defaultCollapseIcon={<ExpandMore />}
          defaultExpandIcon={<ChevronRight />}
          expanded={expanded}
          onNodeToggle={(_, expanded) => setExpanded(expanded)}
        >
          <TreeItem
            nodeId="all"
            label={
              <FormControlLabel
                control={<Checkbox />}
                label="전체 선택"
                checked={innerSelected.length >= products.length}
                onChange={(_, checked) => {
                  if (checked) {
                    setInnerSelected(products);
                    setExpanded([...expanded, "all"]);
                  } else {
                    setInnerSelected([]);
                  }
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            }
          >
            {products.map((product) => {
              const productSelection = innerSelected.find(
                (p) => p.id === product.id
              );
              return (
                <TreeItem
                  key={product.id}
                  nodeId={product.id.toString()}
                  label={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <FormControlLabel
                        label={product.name}
                        control={<Checkbox />}
                        checked={productSelection != null}
                        onChange={(_, checked) => {
                          if (checked) {
                            setInnerSelected([...innerSelected, product]);
                            setExpanded([...expanded, product.id.toString()]);
                          } else {
                            setInnerSelected(
                              innerSelected.filter((s) => s.id !== product.id)
                            );
                            setExpanded(
                              expanded.filter(
                                (e) => e !== product.id.toString()
                              )
                            );
                          }
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                      {product.options && product.options.length > 0 && (
                        <Typography variant="caption" color="secondary">
                          옵션{" "}
                          {productSelection?.options &&
                          productSelection?.options.length ===
                            product.options.length
                            ? "전체"
                            : `${
                                productSelection?.options?.length ?? 0
                              }개`}{" "}
                          선택
                        </Typography>
                      )}
                    </Box>
                  }
                >
                  {product.options &&
                    product.options.map((option) => {
                      const optionSelection = productSelection?.options?.find(
                        (o) => o.id === option.id
                      );
                      return (
                        <TreeItem
                          key={option.id}
                          nodeId={option.id.toString()}
                          label={
                            <FormControlLabel
                              key={option.id}
                              label={option.name}
                              control={<Checkbox />}
                              checked={optionSelection != null}
                              onChange={(_, checked) => {
                                setInnerSelected(
                                  productSelection != null
                                    ? innerSelected.map((p) => {
                                        if (p.id === product.id) {
                                          if (checked) {
                                            return {
                                              ...p,
                                              options: [
                                                ...(p.options ?? []),
                                                option,
                                              ],
                                            };
                                          } else {
                                            return {
                                              ...p,
                                              options: p.options?.filter(
                                                (o) => o.id !== option.id
                                              ),
                                            };
                                          }
                                        } else {
                                          return p;
                                        }
                                      })
                                    : [
                                        ...innerSelected,
                                        {
                                          ...product,
                                          options: [option],
                                        },
                                      ]
                                );
                              }}
                            />
                          }
                        />
                      );
                    })}
                </TreeItem>
              );
            })}
          </TreeItem>
        </TreeView>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setSelected(innerSelected);
            onClose();
          }}
          color="primary"
        >
          적용
        </Button>
        <Button onClick={onClose} color="inherit">
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryDialog;
