import { AxiosInstance } from "axios";

export type OrderMenuOption = {
  id: number;
  name: string;
};

export type OrderMenu = {
  id: number;
  name: string;
  options: OrderMenuOption[];
};

export const orderMenusKey = (idCategory: number) => ["orderMenus", idCategory];

export const orderMenusQuery = (
  client: AxiosInstance,
  idBranchMenu: number
) => ({
  queryKey: orderMenusKey(idBranchMenu),
  queryFn: () =>
    client
      .request<OrderMenu[]>({
        method: "GET",
        url: "v1/partner/orders/menus",
        params: {
          idBranchMenu,
        },
      })
      .then((response) => response.data),
});
