import { AxiosInstance } from "axios";
import { format } from "date-fns";

export type EntryTotalDataParams = {
  idBranchMenu?: number;
  idsMenu?: number[];
  idBranchETicket?: number;
  idsETicket?: number[];
  startDate?: Date;
  endDate?: Date;
};

export type EntryQrCheck = {
  count: number;
  mobile: string;
  name: string;
  receiptCount: number;
  receiptDate: string;
  registerCount: number;
  reservationStore: string;
  ticketName: string;
};

export type EntryTotalData = {
  categoryName: string;
  entryCount: number;
  entryRate: number;
  idCategory: number;
  noEntryCount: number;
  registerRate: number;
  saleCount: number;
  qrCheckThumbResBodies: EntryQrCheck[];
};

export const entryTotalDataKey = (params: EntryTotalDataParams) => [
  "entryTotalData",
  params,
];

export const entryTotalDataQuery = (
  client: AxiosInstance,
  params: EntryTotalDataParams
) => ({
  queryKey: entryTotalDataKey(params),
  queryFn: () =>
    client
      .request<EntryTotalData>({
        method: "GET",
        url: "v1/partner/entries/total-data",
        params: {
          ...params,
          startDate:
            params.startDate == null
              ? undefined
              : format(params.startDate, "yyyy-MM-dd"),
          endDate:
            params.endDate == null
              ? undefined
              : format(params.endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => response.data),
});
