import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import ApiError, { useApi } from "../../../../ApiProvider";
import CalculationPaper from "../../../../components/CalculationPaper";
import PageLayout from "../../../../components/PageLayout";
import PendingFallback from "../../../../components/PendingFallback";
import { meQuery } from "../../../../features/auth/meQuery";
import { calculationConfirmMutation } from "../../../../features/calculation/calculationConfirmMutation";
import { calculationDeleteMutation } from "../../../../features/calculation/calculationDeleteMutation";
import {
  calculationKey,
  calculationQuery,
} from "../../../../features/calculation/calculationQuery";
import { calculationSubmitMutation } from "../../../../features/calculation/calculationSubmitMutation";

const CalculationDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const { client } = useApi();
  const queryClient = useQueryClient();
  const params = useParams();
  const id = parseInt(params.idCalculation as string, 10);
  const amISuper = useQuery({
    ...meQuery(client),
    select: (me) => me.authorities.includes("ROLE_SUPER"),
  });
  const userType = amISuper.data ? "super" : "partner";
  const calculation = useQuery(calculationQuery(client, id));
  const confirm = useMutation({
    ...calculationConfirmMutation(client, queryClient),
  });
  const submit = useMutation({
    ...calculationSubmitMutation(client, queryClient),
  });
  const remove = useMutation({
    ...calculationDeleteMutation(client),
  });
  const handleApprove = () => {
    if (calculation.data) {
      confirm.mutate(
        {
          ...calculation.data,
          calculationStatus: "APPROVAL",
          reasonOfDisapproval: null,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(calculationKey(calculation.data.id));
          },
          onError: ApiError.alertError,
        }
      );
    }
  };
  const handleDisapprove = (reason: string) => {
    if (calculation.data) {
      confirm.mutate(
        {
          ...calculation.data,
          calculationStatus: "DISAPPROVAL",
          reasonOfDisapproval: reason,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(calculationKey(calculation.data.id));
          },
          onError: ApiError.alertError,
        }
      );
    }
  };
  const handleSubmit = () => {
    if (calculation.data) {
      submit.mutate(
        {
          ...calculation.data,
          calculationStatus: "SUBMIT",
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(calculationKey(calculation.data.id));
          },
          onError: ApiError.alertError,
        }
      );
    }
  };
  const handleDelete = () => {
    remove.mutate(id, {
      onSuccess: () => navigate("../calculations"),
    });
  };
  return (
    <PageLayout title="정산서 보기">
      {calculation.data == null ? (
        <PendingFallback />
      ) : (
        <CalculationPaper
          id={id}
          userType={userType}
          status={calculation.data.calculationStatus}
          rejectMessage={
            calculation.data.calculationStatus === "DISAPPROVAL"
              ? calculation.data.reasonOfDisapproval ?? undefined
              : undefined
          }
          businessName={calculation.data.businessName}
          name={calculation.data.name}
          email={calculation.data.email}
          memo={calculation.data.memo}
          phone={calculation.data.phone}
          calculatedEndDate={calculation.data.calculatedEndDate}
          fileUrls={calculation.data.fileUrls}
          onApprove={handleApprove}
          onDisapprove={handleDisapprove}
          onSubmit={handleSubmit}
          onDelete={handleDelete}
        />
      )}
    </PageLayout>
  );
};

export default CalculationDetailPage;
