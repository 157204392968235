import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import {} from "@tanstack/react-query";
import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import CalculationRejectDialog from "./CalculationRejectDialog";
import ConfirmDialog from "./ConfirmDialog";

export type CalculationPaperProps = {
  id: number;
  userType: "partner" | "super";
  rejectMessage?: string;
  status: "INIT" | "MODIFY" | "SUBMIT" | "APPROVAL" | "DISAPPROVAL";
  businessName: string;
  name: string;
  email: string;
  memo: string;
  phone: String;
  calculatedEndDate: string;
  fileUrls: string[];
  onApprove: () => void;
  onDisapprove: (reason: string) => void;
  onSubmit: () => void;
  onDelete: () => void;
};

const CalculationPaper: React.FC<CalculationPaperProps> = ({
  id,
  userType,
  rejectMessage,
  status,
  businessName,
  name,
  email,
  memo,
  phone,
  calculatedEndDate,
  fileUrls,
  onApprove,
  onDisapprove,
  onSubmit,
  onDelete,
}) => {
  const [rejectOpened, setRejectOpened] = useState(false);
  const [confirmOpened, setConfirmOpened] = useState(false);
  const [deleteOpened, setDeleteOpened] = useState(false);
  const [submitOpened, setSubmitOpened] = useState(false);
  const data = [
    // { title: "제목", value: businessName },
    {
      title: "정산 담당자",
      value: [name, email].join(" / "),
    },
    { title: "정산 담당자 연락처", value: phone ?? "-" },
    {
      title: "정산 마감일",
      value: calculatedEndDate ?? "-",
    },
    {
      title: "정산 담당자 전달사항",
      value: memo ?? "-",
    },
    {
      title: `정산서`,
      value: fileUrls.map((fileUrl, index) => (
        <Fragment key={fileUrl}>
          <a download href={fileUrl}>
            {fileUrl.split("/").reverse()[0]}
          </a>
          {index - 1 < fileUrls.length && <br />}
        </Fragment>
      )),
    },
  ];
  return (
    <>
      {
        <Card>
          <CardContent
            sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            {status === "APPROVAL" ? (
              <Alert color="success">정산서가 승인되었습니다.</Alert>
            ) : status === "DISAPPROVAL" ? (
              <Alert color="error">정산서가 반려되었습니다.</Alert>
            ) : status === "MODIFY" ? (
              <Alert color="warning">
                정산서 수정중입니다.{" "}
                {userType === "super" && "작성 완료시 전송 버튼을 눌러주세요."}
              </Alert>
            ) : status === "SUBMIT" ? (
              <Alert color={userType === "partner" ? "warning" : "success"}>
                정산서가 발송되었습니다.{" "}
                {userType === "partner" && "확인해주세요."}
              </Alert>
            ) : status === "INIT" ? (
              <Alert color="warning">
                정산서 작성중입니다.{" "}
                {userType === "super" && "작성 완료시 전송 버튼을 눌러주세요."}
              </Alert>
            ) : null}
            <Table>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item.title}>
                    <TableCell
                      sx={{ backgroundColor: "grey.100", fontWeight: "bold" }}
                    >
                      {item.title}
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }}>
                      {item.value}
                    </TableCell>
                  </TableRow>
                ))}
                {rejectMessage != null && (
                  <TableRow>
                    <TableCell
                      sx={{
                        backgroundColor: "error.main",
                        color: "error.contrastText",
                        fontWeight: "bold",
                      }}
                    >
                      반려사유
                    </TableCell>
                    <TableCell sx={{ color: "text.secondary" }}>
                      {rejectMessage}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                gap: "0.5rem",
                justifyContent: "flex-end",
              }}
            >
              {userType === "partner" ? (
                <>
                  <Button
                    color="primary"
                    variant="contained"
                    disabled={status !== "SUBMIT"}
                    onClick={() => setConfirmOpened(true)}
                  >
                    정산 승인
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    disabled={status !== "SUBMIT"}
                    onClick={() => setRejectOpened(true)}
                  >
                    정산 반려
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    color="secondary"
                    variant="contained"
                    component={Link}
                    to={`../calculations/${id}/edit`}
                    disabled={status === "APPROVAL"}
                  >
                    수정
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => setDeleteOpened(true)}
                  >
                    삭제
                  </Button>
                  {status === "INIT" || status === "MODIFY" ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setSubmitOpened(true)}
                    >
                      전송
                    </Button>
                  ) : null}
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      }
      <CalculationRejectDialog
        open={rejectOpened}
        onSubmit={onDisapprove}
        onClose={() => setRejectOpened(false)}
      />
      <ConfirmDialog
        open={confirmOpened}
        onOk={onApprove}
        onClose={() => setConfirmOpened(false)}
        message="최종 정산을 승인하시겠습니까?"
        okText="승인"
      />
      <ConfirmDialog
        open={deleteOpened}
        onOk={onDelete}
        onClose={() => setDeleteOpened(false)}
        message="정말 삭제하시겠습니까?"
      />
      <ConfirmDialog
        open={submitOpened}
        onOk={onSubmit}
        onClose={() => setSubmitOpened(false)}
        message="현재 내용으로 전송하시겠습니까?"
      />
    </>
  );
};

export default CalculationPaper;
