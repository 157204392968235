import { AxiosResponse } from "axios";

export function downloadBlobResponse(
  response: AxiosResponse<Blob>,
  filenameAs?: string
) {
  const href = URL.createObjectURL(response.data);
  let filename =
    response.headers["content-disposition"]
      ?.split("filename=")[1]
      .split(";")[0]
      .replaceAll('"', "") ?? filenameAs;
  filename = decodeURIComponent(filename).replaceAll("+", " ");
  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}
