import { Box, Card, CardContent } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { parseISO } from "date-fns";
import { useApi } from "../../../../ApiProvider";
import { CategoryFilterCategory } from "../../../../components/CategoryFilter";
import EntryPerTimeChart from "../../../../components/EntryPerTimeChart";
import EntryPerTimeTable from "../../../../components/EntryPerTimeTable";
import { entrySummaryDataQuery } from "../../../../features/entry/entrySummaryDataQuery";

export type EntriesPerTimeTabProps = {
  queens: CategoryFilterCategory[];
  etickets: CategoryFilterCategory[];
};

const EntriesPerTimeTab: React.FC<EntriesPerTimeTabProps> = ({
  queens,
  etickets,
}) => {
  const { client } = useApi();
  const idsMenu = queens.map((q) => q.products.map((p) => p.id)).flat();
  const idsETicket = etickets.map((e) => e.products.map((p) => p.id)).flat();
  const entrySummary = useQuery(
    entrySummaryDataQuery(client, idsMenu, idsETicket)
  );
  return entrySummary.data == null ? null : (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "grid",
            alignItems: "flex-start",
            gridTemplateColumns: {
              xs: "1fr",
              md: "2fr 1fr",
            },
          }}
        >
          <EntryPerTimeChart
            items={entrySummary.data.eticketEntryByTimeResBodies}
          />
          <EntryPerTimeTable
            date={parseISO(entrySummary.data.searchDateTime)}
            items={entrySummary.data.eticketEntryByTimeResBodies}
          />
        </Box>
      </CardContent>
    </Card>
  );
};

export default EntriesPerTimeTab;
