import { AxiosInstance } from "axios";

export type FileUploadResult = {
  extension: string;
  filename: string;
  size: number;
  url: string;
};

export const fileUploadMutation = (client: AxiosInstance) => ({
  mutationFn: (file: File) => {
    const extension = file.name.split(".").reverse()[0];
    const url = extension === "xlsx" ? `v1/upload/excelfile` : `v1/upload/file`;
    const formData = new FormData();
    formData.append("file", file);
    return client
      .request<FileUploadResult>({
        method: "POST",
        url,
        data: formData,
      })
      .then((response) => response.data);
  },
});
