import { AxiosInstance } from "axios";

export type ETicketEntryByTime = {
  startTime: string;
  endTime: string;
  attendanceCount: number;
};

export type EntrySummaryData = {
  attendanceRate: number;
  totalCount: number;
  attendance: number;
  totalCountByQueensSmile: number;
  attendanceByQueensSmile: number;
  totalCountByETicket: number;
  attendanceByETicket: number;
  searchDateTime: string;
  eticketEntryByTimeResBodies: ETicketEntryByTime[];
};

export const entrySummaryDataKey = (
  idsMenu: number[],
  idsETicket: number[]
) => ["entriesSummaryData", idsMenu, idsETicket];

export const entrySummaryDataQuery = (
  client: AxiosInstance,
  idsMenu: number[],
  idsETicket: number[]
) => ({
  queryKey: entrySummaryDataKey(idsMenu, idsETicket),
  queryFn: () =>
    client
      .request<EntrySummaryData>({
        method: "GET",
        url: "v1/partner/entries/summary-data",
        params: {
          idsMenu,
          idsETicket,
        },
      })
      .then((response) => response.data),
});
