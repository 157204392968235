import { createContext, ReactNode, useContext } from "react";

import koKR from "../../data/translations/ko-KR.json";

export type TranslationContextValue = {
  language: string;
  translations: typeof koKR;
};

export const TranslationContext = createContext<TranslationContextValue>({
  language: "ko-KR",
  translations: undefined!,
});

export type TranslationProviderProps = {
  language: string;
  children: ReactNode;
};

function TranslationProvider({ language, children }: TranslationProviderProps) {
  const translations = koKR;
  return (
    <TranslationContext.Provider value={{ language, translations }}>
      {children}
    </TranslationContext.Provider>
  );
}

export default TranslationProvider;

export function useTranslations() {
  return useContext(TranslationContext).translations;
}
