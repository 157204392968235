import { AxiosInstance } from "axios";

export type CalculationStatus =
  | "INIT"
  | "MODIFY"
  | "SUBMIT"
  | "APPROVAL"
  | "DISAPPROVAL";

export type Calculation = {
  branchName: string;
  businessName: string;
  calculatedEndDate: string;
  calculatedStartDate: string;
  calculationStatus: CalculationStatus;
  email: string;
  fileUrls: string[];
  id: number;
  idBranch: number | null;
  idBusiness: number;
  memo: string;
  name: string;
  phone: string;
  reasonOfDisapproval: string | null;
  sendDateTime: string | null;
};

export const calculationKey = (id?: number) =>
  ["calculation", id].filter(Boolean);

export const calculationQuery = (client: AxiosInstance, id: number) => ({
  queryKey: calculationKey(id),
  queryFn: () =>
    client
      .request<Calculation>({
        method: "GET",
        url: `v1/calculations/${id}`,
      })
      .then((response) => response.data),
  enabled: id != null,
});
