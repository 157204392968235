import { AxiosInstance } from "axios";
import { parseISO } from "date-fns";

export type BranchDetail = {
  branchName: string;
  businessName: string;
  idBranch: number;
  idBusiness: number;
  image: string;
  startDate: Date;
  endDate: Date;
};

export const branchKey = (idBranch: number, idBusiness: number) => [
  "branch",
  idBranch,
  idBusiness,
];

export const branchQuery = (
  client: AxiosInstance,
  idBranch: number,
  idBusiness: number
) => ({
  queryKey: branchKey(idBranch, idBusiness),
  queryFn: () =>
    client
      .request<BranchDetail>({
        method: "GET",
        url: `v1/partner/branches/${idBusiness}-${idBranch}`,
      })
      .then((response) => ({
        ...response.data,
        startDate: parseISO(response.data.startDate as unknown as string),
        endDate: parseISO(response.data.endDate as unknown as string),
      })),
});
