import { createTheme, Shadows } from "@mui/material";

export const createShadow = (level: number) =>
  [
    `0px ${level}px ${level * 2}px -${level / 2}px rgba(0,0,0,0.1)`,
    `0px ${level * 2}px ${level * 4}px -${level}px rgba(0,0,0,0.2)`,
  ].join(",");

export const theme = createTheme({
  palette: {
    primary: {
      main: "#FF006E",
    },
    secondary: {
      main: "#333",
    },
    background: {
      default: "#f6f6f6",
    },
  },
  shadows: [
    "none",
    ...Array.from({ length: 25 }).map((_, level) => createShadow(level + 1)),
  ] as Shadows,
  typography: {
    fontFamily: ["Pretendard", "sans-serif"].join(","),
    allVariants: {
      letterSpacing: "-0.03rem",
    },
    h1: { fontSize: "2.2rem", fontWeight: "500" },
    h2: { fontSize: "2rem", fontWeight: "500" },
    h3: { fontSize: "1.8rem", fontWeight: "500" },
    h4: { fontSize: "1.6rem", fontWeight: "500" },
    h5: { fontSize: "1.4rem", fontWeight: "500" },
    h6: { fontSize: "1.2rem", fontWeight: "500" },
  },
  //   components: {
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           borderRadius: "100rem",
  //         },
  //       },
  //     },
  //   },
});

console.log(theme);
