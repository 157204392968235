import { Add, AttachFile, Delete } from "@mui/icons-material";
import {
  Card,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { pickFiles } from "../utils/pickFiles";

export type FilePickerProps = {
  files?: string[];
  onUpload: (file: File) => void;
  onDelete: (url: string) => void;
};

const FilePicker: React.FC<FilePickerProps> = ({
  files,
  onUpload,
  onDelete,
}) => {
  const handlePick = async () => {
    const newFiles = await pickFiles("*", false);
    if (newFiles[0]) {
      onUpload(newFiles[0]);
    }
  };
  return (
    <Card>
      <List>
        {files
          ?.map((url) => url.split("/").reverse()[0])
          .map((filename) => (
            <ListItem key={filename}>
              <ListItemIcon>
                <AttachFile />
              </ListItemIcon>
              <ListItemText primary={filename} />
              <IconButton>
                <Delete />
              </IconButton>
            </ListItem>
          ))}
        <ListItem disablePadding>
          <ListItemButton onClick={handlePick}>
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText primary="파일 추가" />
          </ListItemButton>
        </ListItem>
      </List>
    </Card>
  );
};

export default FilePicker;
