import { Box } from "@mui/material";
import logo from "../images/logo.svg";

export type LogoProps = {};

const Logo: React.FC<LogoProps> = () => {
  return (
    <Box component="img" width="10rem" src={logo} alt="Logo" display="flex" />
  );
};

export default Logo;
