import { CssBaseline, ThemeProvider } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import DefaultLayout from "./layouts/DefaultLayout";
import { theme } from "./theme";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import { useApi } from "./ApiProvider";
import TranslationProvider from "./features/translation/TranslationContext";
import AuthLayout from "./layouts/AuthLayout";
import AuthPage from "./pages/auth";
import BranchesPage from "./pages/branches";
import BranchDetailPage from "./pages/branches/detail";
import CalculationPage from "./pages/branches/detail/calculations";
import CalculationDetailPage from "./pages/branches/detail/calculations/detail";
import CalculationFormPage from "./pages/branches/detail/calculations/form";
import BranchEntriesPage from "./pages/branches/detail/entries";
import BranchOrdersPage from "./pages/branches/detail/orders";

function App() {
  const { accessToken } = useApi();
  return (
    <ThemeProvider theme={theme}>
      <TranslationProvider language="ko-KR">
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <CssBaseline />
          <Routes>
            {accessToken == null ? (
              <>
                <Route path="auth" element={<AuthLayout />}>
                  <Route path="" element={<AuthPage />} />
                </Route>
                <Route path="*" element={<Navigate to="auth" replace />} />
              </>
            ) : (
              <>
                <Route path="" element={<DefaultLayout />}>
                  <Route path="branches" element={<BranchesPage />} />
                  <Route path="branches/:id" element={<BranchDetailPage />}>
                    <Route path="orders" element={<BranchOrdersPage />} />
                    <Route path="entries" element={<BranchEntriesPage />} />
                    <Route path="calculations" element={<CalculationPage />} />
                    <Route
                      path="calculations/:idCalculation"
                      element={<CalculationDetailPage />}
                    />
                    <Route
                      path="calculations/:idCalculation/edit"
                      element={<CalculationFormPage />}
                    />
                    <Route
                      path="calculations/add"
                      element={<CalculationFormPage />}
                    />
                    <Route
                      path=""
                      element={<Navigate to="entries" replace />}
                    />
                  </Route>
                  <Route path="" element={<Navigate to="branches" replace />} />
                </Route>
                <Route path="*" element={<Navigate to="" replace />} />
              </>
            )}
          </Routes>
        </LocalizationProvider>
      </TranslationProvider>
    </ThemeProvider>
  );
}

export default App;
