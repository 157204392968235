import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export type FooterProps = {};

const Footer: React.FC<FooterProps> = () => {
  return (
    <Box
      sx={{
        padding: "1rem",
        textAlign: "center",
        borderTop: "1px solid",
        borderTopColor: "divider",
        backgroundColor: "background.paper",
      }}
    >
      <Typography color="text.secondary" fontSize="0.875rem">
        © 2023 퀸즈(주) All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
