import { AccountCircle } from "@mui/icons-material";
import {
  Box,
  Button,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../ApiProvider";
import { meQuery } from "../features/auth/meQuery";
import Logo from "./Logo";

export type HeaderProps = {};

const Header: React.FC<HeaderProps> = () => {
  const { client, setAccessToken } = useApi();
  const [anchor, setAnchor] = useState<Element | null>(null);
  const me = useQuery(meQuery(client));
  return (
    <>
      <Box
        sx={{
          position: "relative",
          padding: 2,
          display: "flex",
          // borderBottom: "1px solid",
          // borderBottomColor: "divider",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "background.paper",
          boxShadow: 2,
          // boxShadow: 4,
        }}
      >
        <Link to="/">
          <Logo />
        </Link>
        <Typography
          color="text.secondary"
          position="absolute"
          top="50%"
          left="50%"
          sx={{
            transform: "translate(-50%, -50%)",
            display: { xs: "none", md: "block" },
          }}
        >
          퀸즈스마일 파트너페이지
        </Typography>
        {me.data != null && (
          <Button
            color="inherit"
            onClick={(e) => setAnchor(e.currentTarget as Element)}
          >
            <Box display="flex" gap={1}>
              <AccountCircle />
              <Typography color="text.secondary">
                {me.data?.fullname}
              </Typography>
            </Box>
          </Button>
        )}
      </Box>
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuList dense sx={{ minWidth: "15rem" }}>
          <MenuItem component={Link} to="/auth">
            <ListItemText onClick={() => setAccessToken(undefined)}>
              로그아웃
            </ListItemText>
          </MenuItem>
        </MenuList>
      </Popover>
    </>
  );
};

export default Header;
