import { Box, MenuItem, Typography } from "@mui/material";
import { Link, NavLink, useLocation } from "react-router-dom";

export type FestivalMenuProps = {
  name?: string;
};

const menu = [
  // { to: "orders", title: "주문관리" },
  { to: "entries", title: "입장관리" },
  { to: "calculations", title: "정산관리" },
];

const BranchMenu: React.FC<FestivalMenuProps> = ({ name }) => {
  const location = useLocation();
  return (
    <Box
      sx={{
        padding: "1rem 0.5rem",
        bgcolor: "secondary.main",
        color: "secondary.contrastText",
        width: {
          xs: "auto",
          md: "15rem",
        },
      }}
    >
      <MenuItem component={Link} to="/branches">
        &larr; 페스티벌
      </MenuItem>
      <Typography
        variant="h6"
        sx={{ fontWeight: "800", lineHeight: 1.2, padding: "0.5rem 1rem" }}
        dangerouslySetInnerHTML={{ __html: name ?? "-" }}
      />
      <Box
        display="flex"
        sx={{
          display: "flex",
          flexDirection: {
            xs: "row",
            md: "column",
          },
        }}
      >
        {menu.map((item) => (
          <MenuItem
            key={item.to}
            component={NavLink}
            to={item.to}
            selected={location.pathname.includes(item.to)}
          >
            {item.title}
          </MenuItem>
        ))}
      </Box>
    </Box>
  );
};

export default BranchMenu;
