import { Error } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { FormEventHandler, useState } from "react";
import ApiError, { useApi } from "../ApiProvider";
import Logo from "../components/Logo";
import { loginMutation } from "../features/auth/loginMutation";
import { useTranslations } from "../features/translation/TranslationContext";
import { useLocalStorage } from "../utils/useLocalStorage";

export type AuthPageLayout = {};

const AuthPage: React.FC<AuthPageLayout> = () => {
  const t = useTranslations();
  const { client, setAccessToken } = useApi();
  const [rememberedEmail, setRememberedEmail] =
    useLocalStorage<string>("email");
  const [userEmail, setUserEmail] = useState(
    rememberedEmail ?? process.env.NODE_ENV === "development"
      ? "partner@a.com"
      : ""
  );
  const [password, setPassword] = useState(
    process.env.NODE_ENV === "development" ? "qwer1234!" : ""
  );
  const [rememberEmail, setRememberEmail] = useState(rememberedEmail != null);
  const login = useMutation({
    ...loginMutation(client),
    onSuccess: (response, request) => {
      setAccessToken(response.data.access_token);
      if (request.rememberEmail) {
        setRememberedEmail(request.userEmail);
      } else {
        setRememberedEmail(undefined);
      }
    },
    onError: (error: any) => {
      alert(error.message);
    },
  });
  const [errorMessage, setErrorMessage] = useState<string>();
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    login.mutate(
      {
        userEmail,
        password,
        rememberEmail,
      },
      {
        onError: (error) => {
          if (error instanceof ApiError) {
            setErrorMessage(
              error?.message === "NOT_VALID_AUTHORITY"
                ? t.AUTH_FORM_NOT_VALID_AUTHORITY
                : error.message
            );
          }
        },
      }
    );
  };
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Logo />
        <Typography color="text.secondary">{t.APP_NAME}</Typography>
      </Box>
      <Divider />
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        {errorMessage && (
          <Alert color="error" icon={<Error />}>
            {errorMessage}
          </Alert>
        )}
        {process.env.NODE_ENV === "development" && (
          <Button
            onClick={() => {
              setUserEmail("queens@the-flan.com");
              setPassword("Devqs1234!");
            }}
          >
            슈퍼관리자
          </Button>
        )}
        <TextField
          label={t.AUTH_FORM_EMAIL}
          variant="outlined"
          placeholder={t.AUTH_FORM_EMAIL_REQUIRED}
          size="small"
          value={userEmail}
          onChange={(e) => setUserEmail(e.target.value)}
        />
        <TextField
          type="password"
          label={t.AUTH_FORM_PASSWORD}
          variant="outlined"
          placeholder={t.AUTH_FORM_PASSWORD_REQUIRED}
          size="small"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <FormControlLabel
          control={<Checkbox />}
          label="이메일 기억하기"
          checked={rememberEmail}
          onChange={(_, checked) => setRememberEmail(checked)}
        />
        <Button type="submit" variant="contained" size="large">
          {t.AUTH_FORM_SUBMIT}
        </Button>
      </Box>
    </>
  );
};

export default AuthPage;
