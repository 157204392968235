import { Box, useTheme } from "@mui/material";
import { useQueries, useQuery } from "@tanstack/react-query";
import { Fragment, useEffect, useState } from "react";
import { Range } from "react-date-range";
import { useParams } from "react-router-dom";
import { useApi } from "../../../ApiProvider";
import CategoryFilter, {
  CategoryFilterCategory,
} from "../../../components/CategoryFilter";
import DataAccordion from "../../../components/DataAccordion";
import DateRangeButton from "../../../components/DateRangeButton";
import Fallback from "../../../components/Fallback";
import PageLayout from "../../../components/PageLayout";
import PendingFallback from "../../../components/PendingFallback";
import Section from "../../../components/Section";
import { branchQuery } from "../../../features/branch/branchQuery";
import { orderCategoriesQuery } from "../../../features/order/orderCategoriesQuery";
import { orderMenusQuery } from "../../../features/order/orderMenusQuery";
import { orderTotalDataExcelDownload } from "../../../features/order/orderTotalDataExcelDownload";
import { orderTotalDataItemPageQuery } from "../../../features/order/orderTotalDataItemPageQuery";
import { orderTotalDataQuery } from "../../../features/order/orderTotalDataQuery";
import { orderTotalSalesQuery } from "../../../features/order/orderTotalSalesQuery";
import { downloadBlobResponse } from "../../../utils/downloadBlobResponse";

export const exampleOrderCategories = [
  {
    id: "ticket",
    name: "Ticket",
    products: [
      { id: "ticket1", name: "Ticket-1", options: [] },
      { id: "ticket2", name: "Ticket-2", options: [] },
    ],
  },
  {
    id: "food",
    name: "Food",
    products: [
      {
        id: "food1",
        name: "Food-1",
        options: [
          { id: "food1option1", name: "Option-1" },
          { id: "food1option2", name: "Option-2" },
          { id: "food1option3", name: "Option-3" },
        ],
      },
      { id: "food2", name: "Food-2", options: [] },
    ],
  },
  {
    id: "locker",
    name: "Locker",
    products: [{ id: "locker1", name: "Locker-1", options: [] }],
  },
  {
    id: "eticket",
    name: "E-Ticket",
    products: [{ id: "eticket1", name: "E-Ticket1", options: [] }],
  },
  {
    id: "parking",
    name: "Parking",
    products: [{ id: "parking1", name: "Parking1", options: [] }],
  },
];

const BranchOrdersPage: React.FC = () => {
  const { client } = useApi();
  const theme = useTheme();
  const params = useParams();
  const [idBranch, idBusiness] = params
    .id!.split("-")
    .map((id) => parseInt(id));
  const id = parseInt(params.id!, 10);
  const branch = useQuery(branchQuery(client, idBranch, idBusiness));
  const [range, setRange] = useState<Range>({
    startDate: branch.data?.startDate,
    endDate: branch.data?.endDate,
    color: theme.palette.primary.main,
  });
  const [selected, setSelected] = useState<CategoryFilterCategory[]>([]);

  // 주문 카테고리 조회
  const orderCategories = useQuery(orderCategoriesQuery(client, id));

  // 카테고리별 메뉴 조회
  const orderMenus = useQueries({
    queries:
      orderCategories.data?.map((c) => orderMenusQuery(client, c.id)) ?? [],
  });

  // 카테고리 셀렉터 컴포넌트에 쓰일 데이터 가공
  const categories = (orderCategories.data ?? []).map((category, index) => ({
    ...category,
    products: orderMenus[index].data ?? [],
  }));

  // 선택된 카테고리별 주문 데이터 조회
  const orderTotalData = useQueries({
    queries: selected.map((category) => ({
      ...orderTotalDataQuery(client, {
        idBranchMenu: category.id,
        idsMenu: category.products.map((product) => product.id),
        // TODO: localDate
      }),
      keepPreviousData: true,
    })),
  });

  const orderTotalDataItemPage = useQueries({
    queries: selected.map((category) => ({
      ...orderTotalDataItemPageQuery(client, {
        idBranchMenu: category.id,
        idsMenu: category.products.map((product) => product.id),
        // TODO: localDate
      }),
      keepPreviousData: true,
    })),
  });

  console.log(orderTotalDataItemPage);

  // 실시간 총 매출
  const total = useQuery({
    ...orderTotalSalesQuery(client, id),
    // refetchInterval: 20 * 1000,
  });

  // 브랜치 데이터가 로딩/변경 된다면, 이를 선택된 날짜 범위에 반영한다.
  useEffect(() => {
    setRange((range) => ({
      ...range,
      startDate: branch.data?.startDate,
      endDate: branch.data?.endDate,
    }));
  }, [branch.data?.startDate, branch.data?.endDate]);

  return (
    <PageLayout title="주문관리">
      <Box display="flex" flexDirection="column" gap="2rem">
        <CategoryFilter
          categories={categories}
          selected={selected}
          setSelected={setSelected}
        />
        <Section
          title="고객"
          right={
            <>
              실시간 총 매출:{" "}
              <strong>{(total.data ?? 0).toLocaleString()}</strong>원
            </>
          }
        />
        <Box>
          <DateRangeButton
            range={range}
            onChange={setRange}
            minDate={branch.data?.startDate}
            maxDate={branch.data?.endDate}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            overflow: "hidden",
          }}
        >
          {orderTotalData.length < 1 ? (
            <Fallback
              message={"조회된 데이터가 없습니다.\n필터를 선택해 주세요."}
            />
          ) : (
            orderTotalData.map((response, index) => {
              const data = response.data;
              const items =
                orderTotalDataItemPage[index].data?._embedded
                  ?.eSMenuThumbResBodies ?? [];
              return (
                <Fragment key={selected[index].id}>
                  {data == null ? (
                    <PendingFallback />
                  ) : (
                    <DataAccordion
                      key={data.idCategory}
                      title={data.categoryName}
                      stats={{
                        "수령비율(%)": `${data.receiptRate.toFixed(1)}%`,
                        "총 판매수량": `${data.saleCount.toLocaleString()}`,
                        "총 판매금액": `${data.totalPrice.toLocaleString()}`,
                        "수령완료 수량": `${data.receiptCount.toLocaleString()}`,
                      }}
                      titles={[
                        "성함",
                        "연락처",
                        "상품명",
                        "관람(수령)일자",
                        "예매처",
                        "수량",
                        "금액",
                        "수령완료",
                      ]}
                      rows={items.map((order) => [
                        order.name || "-",
                        order.mobile || "-",
                        order.menuName || "-",
                        order.receiptDate || "-",
                        order.reservationStore || "-",
                        `${order.count}개`,
                        `${order.price.toLocaleString()}원`,
                        `${order.receiptCount}개`,
                      ])}
                      onExcelDownload={() =>
                        orderTotalDataExcelDownload(
                          client,
                          selected[index].id,
                          selected[index].products.map((product) => product.id)
                        )
                          .then((response) =>
                            downloadBlobResponse(
                              response,
                              // TODO: Add date range
                              `${data.categoryName}_고객.xlsx`
                            )
                          )
                          .catch((err) => alert(err.message))
                      }
                    />
                  )}
                </Fragment>
              );
            })
          )}
        </Box>
      </Box>
    </PageLayout>
  );
};

export default BranchOrdersPage;
