import { AxiosInstance } from "axios";
import { Calculation, CalculationStatus } from "./calculationQuery";

export type CalculationPageParams = {
  query?: string;
  startDate?: Date;
  endDate?: Date;
  idBranch: number;
  idsBusiness?: number[];
  calculationStatus?: CalculationStatus;
  size?: number;
  page?: number;
};

export type CalculationPageResult = {
  content: Calculation[];
  empty: boolean;
  first: boolean;
  last: boolean;
  number: number;
  numberOfElements: number;
  pageable: {
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    sort: { sorted: boolean; unsorted: boolean; empty: boolean };
    unpaged: boolean;
  };
  size: number;
  sort: { sorted: boolean; unsorted: boolean; empty: boolean };
  totalElements: number;
  totalPages: number;
};

export const calculationPageKey = (params?: CalculationPageParams) =>
  ["calculationPage", params].filter(Boolean);

export const calculationPageQuery = (
  client: AxiosInstance,
  params: CalculationPageParams
) => ({
  queryKey: calculationPageKey(params),
  queryFn: () =>
    client
      .request<CalculationPageResult>({
        method: "GET",
        url: "v1/calculations",
        params,
      })
      .then((response) => response.data),
});
