import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useState } from "react";

export type CalculationRejectDialogProps = {
  open: boolean;
  onSubmit: (reason: string) => void;
  onClose: () => void;
};

const CalculationRejectDialog: React.FC<CalculationRejectDialogProps> = ({
  open,
  onSubmit,
  onClose,
}) => {
  const [reason, setReason] = useState("");
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>정산 반려</DialogTitle>
      <DialogContent>
        <TextField
          placeholder="반려 사유를 입력해 주세요."
          fullWidth
          multiline
          variant="standard"
          minRows={3}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onSubmit(reason);
            onClose();
          }}
        >
          보내기
        </Button>
        <Button color="secondary" onClick={onClose}>
          취소
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CalculationRejectDialog;
