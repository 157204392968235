import { AxiosInstance } from "axios";
import { format } from "date-fns";

export type EntryQrCheck = {
  count: number;
  mobile: string;
  name: string;
  receiptCount: number;
  receiptDate: string;
  registerCount: number;
  reservationStore: string;
  ticketName: string;
};

export type EntryTotalDataItemPage = {
  page: {
    size: number;
    totalElements: number;
    totalPages: number;
    number: 0;
  };
  _embedded?: {
    qrCheckThumbResBodies?: EntryQrCheck[];
  };
};

export type EntryTotalDataItemPageParams = {
  idBranchMenu?: number;
  idsMenu?: number[];
  idBranchETicket?: number;
  idsETicket?: number[];
  startDate?: Date;
  endDate?: Date;
};

export const entryTotalDataItemPageKey = (
  params: EntryTotalDataItemPageParams
) => ["entryTotalDataItemPage", params];

export const entryTotalDataItemPageQuery = (
  client: AxiosInstance,
  params: EntryTotalDataItemPageParams
) => ({
  enabled: false,
  queryKey: entryTotalDataItemPageKey(params),
  queryFn: () => {
    return client
      .request<EntryTotalDataItemPage>({
        method: "GET",
        url: "v1/partner/entries/total-data/list",
        params: {
          ...params,
          startDate:
            params.startDate == null
              ? undefined
              : format(params.startDate, "yyyy-MM-dd"),
          endDate:
            params.endDate == null
              ? undefined
              : format(params.endDate, "yyyy-MM-dd"),
        },
      })
      .then((response) => response.data);
  },
});
