import { AxiosInstance } from "axios";

export type EsMenuThumbResBody = {
  name: string;
  mobile: string;
  menuName: string;
  receiptDate: string;
  reservationStore: string;
  count: number;
  price: number;
  receiptCount: number;
};

export type OrderTotalData = {
  idCategory: number;
  categoryName: string;
  receiptRate: number;
  saleCount: number;
  totalPrice: number;
  receiptCount: number;
};

export type OrderTotalDataParams = {
  idBranchMenu: number;
  idsMenu: number[];
  localDate?: Date;
};

export const orderTotalDataKey = (params: OrderTotalDataParams) => [
  "orderTotalData",
  params,
];

export const orderTotalDataQuery = (
  client: AxiosInstance,
  params: OrderTotalDataParams
) => ({
  queryKey: orderTotalDataKey(params),
  queryFn: () =>
    client
      .request<OrderTotalData>({
        method: "GET",
        url: "v1/partner/orders/total-data",
        params,
      })
      .then((response) => response.data),
});
