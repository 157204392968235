import { Box, Card, CardContent } from "@mui/material";
import { Outlet } from "react-router-dom";

export type AuthLayoutProps = {};

const AuthLayout: React.FC<AuthLayoutProps> = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minHeight: "100vh",
      }}
    >
      <Card sx={{ minWidth: "25rem" }}>
        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            padding: "2rem 1rem",
          }}
        >
          <Outlet />
        </CardContent>
      </Card>
    </Box>
  );
};

export default AuthLayout;
