import axios, { AxiosInstance } from "axios";
import * as qs from "qs";
import { createContext, ReactNode, useContext, useMemo } from "react";
import { useLocalStorage } from "./utils/useLocalStorage";

export default class ApiError extends Error {
  constructor(public status: number, message: string) {
    super(message);

    // Extending Error class in typescript
    Object.setPrototypeOf(this, new.target.prototype);
  }

  toJSON() {
    return {
      message: this.message,
      status: this.status,
    };
  }

  static fromError(error: Error) {
    if (axios.isAxiosError(error)) {
      const data = error.response?.data as any;

      // 비즈니스 Exception
      if (data?.message != null) {
        return new ApiError(
          error.response?.status ?? 500,
          data?.message ?? "에러가 발생했습니다."
        );
      }
    }
    return error;
  }

  static alertError(error: unknown) {
    if (error instanceof ApiError) {
      alert(error.message ?? "에러가 발생했습니다.");
    } else {
      alert("에러가 발생했습니다.");
    }
  }
}

export type ApiContextValues = {
  accessToken?: string;
  setAccessToken: (accessToken?: string) => void;
  client: AxiosInstance;
};

export const ApiContext = createContext<ApiContextValues>({
  accessToken: undefined,
  setAccessToken: () => {
    throw new Error("Should use inside ApiContext");
  },
  client: axios,
});

export type ApiProviderProps = {
  baseURL?: string;
  children?: ReactNode;
};

export const ApiProvider: React.FC<ApiProviderProps> = ({
  baseURL,
  children,
}) => {
  const [accessToken, setAccessToken] = useLocalStorage<string>("access_token");
  const headers = useMemo(() => {
    const record: Record<string, string> = {};
    if (accessToken != null) {
      record["Authorization"] = `Bearer ${accessToken}`;
    }
    return record;
  }, [accessToken]);
  const client = useMemo(() => {
    const client = axios.create({
      baseURL: baseURL,
      headers,
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "repeat" }),
    });

    client.interceptors.response.use(
      (r) => r,
      (error) => {
        const apiError = ApiError.fromError(error);
        // 401 혹은 403시 로그아웃 처리
        if (
          apiError instanceof ApiError &&
          [401, 403].includes(apiError.status)
        ) {
          setAccessToken(undefined);
        }
        throw apiError;
      }
    );
    return client;
  }, [baseURL, headers, setAccessToken]);
  return (
    <ApiContext.Provider value={{ accessToken, setAccessToken, client }}>
      {children}
    </ApiContext.Provider>
  );
};

export function useApi() {
  return useContext(ApiContext);
}
