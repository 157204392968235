import { Box, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import CategoryCheckbox from "./CategoryCheckbox";
import CategoryDialog from "./CategoryDialog";

export type CategoryFilterOption = {
  id: number;
  name: string;
};

export type CategoryFilterProduct = {
  id: number;
  name: string;
  options?: CategoryFilterOption[];
};

export type CategoryFilterCategory = {
  id: number;
  name: string;
  products: CategoryFilterProduct[];
};

export type CategoryFilterProps = {
  title?: string;
  categories: CategoryFilterCategory[];
  selected: CategoryFilterCategory[];
  setSelected: (selected: CategoryFilterCategory[]) => void;
};

const CategoryFilter: React.FC<CategoryFilterProps> = ({
  title,
  categories,
  selected,
  setSelected,
}) => {
  const [opened, setOpened] = useState<number>();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}>
      {title && <Typography variant="h6">{title}</Typography>}
      <Box
        sx={{
          display: "grid",
          gap: "0.5rem",
          gridTemplateColumns: {
            xs: "1fr 1fr",
            lg: "1fr 1fr 1fr",
            xl: "1fr 1fr 1fr 1fr",
          },
        }}
      >
        {categories.map((category) => {
          const selection = selected.find((s) => s.id === category.id);
          return (
            <Fragment key={category.id}>
              <CategoryCheckbox
                label={category.name}
                count={selection?.products.length}
                totalCount={category.products.length}
                checked={!!selection}
                disabled={category.products.length === 0}
                onChange={(checked) => {
                  if (checked) {
                    setSelected([category]);
                    // setSelected([...selected, category]);
                  } else {
                    setSelected([]);
                    // setSelected(selected.filter((s) => s.id !== category.id));
                  }
                }}
                filterEnabled={category.products.length > 0}
                onClickFilter={() => setOpened(category.id)}
              />
              <CategoryDialog
                name={category.name}
                open={opened === category.id}
                onClose={() => setOpened(undefined)}
                products={category.products}
                selected={selection?.products ?? []}
                setSelected={(products) => {
                  if (products.length > 0) {
                    setSelected([
                      {
                        ...category,
                        products,
                      },
                    ]);
                    // setSelected(
                    //   selection != null
                    //     ? selected.map((c) =>
                    //         c.id === category.id ? { ...c, products } : c
                    //       )
                    //     : [...selected, { ...category, products }]
                    // );
                  } else {
                    setSelected([]);
                    // setSelected(selected.filter((c) => c.id !== category.id));
                  }
                }}
              />
            </Fragment>
          );
        })}
      </Box>
    </Box>
  );
};

export default CategoryFilter;
