import { AxiosInstance } from "axios";

export const calculationDeleteMutation = (client: AxiosInstance) => ({
  mutationFn: (id: number) =>
    client
      .request({
        method: "DELETE",
        url: `v1/calculations/delete/${id}`,
      })
      .then((response) => response.data),
});
