export const pickFiles = (accept: string, multiple: boolean = true) => {
  return new Promise<File[]>((resolve) => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = accept;
    input.multiple = multiple;
    input.style.position = "absolute";
    input.style.top = "-9999px";
    input.addEventListener("change", (e) => {
      const target = e.target as HTMLInputElement;
      input.remove();
      resolve(Array.from(target.files ?? []));
    });
    document.body.appendChild(input);
    input.click();
  });
};
