import { FilterList } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  FormControlLabel,
  IconButton,
  Typography,
} from "@mui/material";

export type CategoryCheckboxProps = {
  label: string;
  checked?: boolean;
  count?: number;
  totalCount?: number;
  disabled?: boolean;
  onChange?: (checked: boolean) => void;
  filterEnabled?: boolean;
  onClickFilter?: () => void;
};

const CategoryCheckbox: React.FC<CategoryCheckboxProps> = ({
  label,
  checked,
  count = 0,
  totalCount = 0,
  disabled,
  onChange,
  filterEnabled,
  onClickFilter,
}) => {
  return (
    <>
      <Card
        sx={{
          padding: {
            xs: "0.5rem",
            sm: "1rem",
          },
          paddingLeft: {
            xs: "0.75rem",
            sm: "1.5rem",
          },
          display: "flex",
          flexDirection: "row",
          gap: "0.5rem",
          alignItems: "center",
          opacity: checked ? 1 : 0.5,
        }}
      >
        <FormControlLabel
          disabled={disabled}
          control={<Checkbox />}
          sx={{
            marginRight: "auto",
            overflow: "hidden",
            ".MuiFormControlLabel-label": { overflow: "hidden" },
          }}
          label={
            <>
              <Typography
                component="div"
                sx={{
                  fontWeight: "bold",
                  lineHeight: "1.2",
                  display: "box",
                  lineClamp: "1",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
                dangerouslySetInnerHTML={{ __html: label ?? "" }}
              />
              <Typography
                component="div"
                variant="caption"
                color="text.secondary"
                sx={{
                  lineHeight: "1",
                  display: "box",
                  lineClamp: "1",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {count === totalCount ? "전체 선택" : count + "개 선택"}
              </Typography>
            </>
          }
          checked={checked}
          onChange={(_, checked) => onChange?.(checked)}
        />
        <IconButton onClick={onClickFilter} disabled={!filterEnabled}>
          <FilterList />
        </IconButton>
      </Card>
    </>
  );
};

export default CategoryCheckbox;
