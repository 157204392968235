import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";

export type FallbackProps = {
  message?: ReactNode;
};

const Fallback: React.FC<FallbackProps> = ({ message }) => {
  return (
    <Box
      sx={{
        padding: "2rem",
        textAlign: "center",
        flex: "1 1 auto",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "pre-line",
      }}
    >
      <Typography color="text.disabled">{message}</Typography>
    </Box>
  );
};

export default Fallback;
